export class Fornecedor {
  constructor ({
    id,
    nome,
    cpf,
    rg,
    codigo,
    nome_fantasia,
    pessoaFis,
    cnpj,
    inscricao_estadual,
    razao_social,
    email,
    telefones,
    pais,
    estado,
    cidade,
    bairro,
    logradouro,
    numero,
    cep,
    complemento,
  } = {
    id: '',
    nome: '',
    cpf: '',
    rg: '',
    codigo: '',
    nome_fantasia: '',
    pessoaFis: '',
    cnpj: '',
    inscricao_estadual: '',
    razao_social: '',
    email: '',
    telefones: [],
    pais: '',
    estado: '',
    cidade: '',
    bairro: '',
    logradouro: '',
    numero: '',
    cep: '',
    complemento: '',
  }) {
    this.id = id
    this.nome = nome
    this.cpf = cpf
    this.rg = rg
    this.codigo = codigo
    this.nome_fantasia = nome_fantasia
    this.pessoaFis = pessoaFis
    this.cnpj = cnpj
    this.inscricao_estadual = inscricao_estadual
    this.razao_social = razao_social
    this.email = email
    this.telefones = telefones
    this.pais = pais
    this.estado = estado
    this.cidade = cidade
    this.bairro = bairro
    this.logradouro = logradouro
    this.numero = numero
    this.cep = cep
    this.complemento = complemento
  }
}

export function createFornecedor (data) {
  return Object.freeze(new Fornecedor(data))
}
