import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Fornecedor } from '@/models/Fornecedor'
import Swal from 'sweetalert2'
import { fornecedorTransformer } from '@/utils/transformers'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    picked: '',
    telefones: [
      { tipo: 'Comercial', format: 'com', title: 'Telefone', required: true },
    ],
    loading: true,
    isEditing: false,
    dialog: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit('SET_STATE', { loading: true, isEditing: false })
    dispatch('form/BOOTSTRAP_FORM', { model: new Fornecedor() }, { root: true })
    if (id) {
      commit(SET_STATE, { isEditing: true, dialog: false })
      await api.getEntidade('fornecedor', id).then(response => {
        commit(SET_STATE, { picked: response.data[0].pessoaFis ? 'fisica' : 'juridica', loading: false })
        dispatch('form/BOOTSTRAP_FORM', { model: new Fornecedor(fornecedorTransformer(response.data[0], state.telefones)) }, { root: true })
      })
    } else {
      commit(SET_STATE, { dialog: true, loading: false, isEditing: false })
    }
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Cadastrando Fornecedor!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data }
        form.pessoaFis = state.picked === 'fisica';
        (() => {
          commit('SET_STATE', { loading: true })
          return state.isEditing
            ? api.salvarEntidade(form, 'fornecedor', form.id)
            : api.cadastrarEntidade(form, 'fornecedor')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Fornecedor atualizado com sucesso!'
                : 'Fornecedor cadastrado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch('fornecedores/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/fornecedores`,
            })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              text: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
