<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <crud-cpf-dialog
      :dialog="dialog"
      :titulo="'Fornecedor'"
      :profile="'fornecedor'"
      :store="'fornecedores'"
      @close-dialog="setFields"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <v-card
          outlined
          class="px-6 pt-4"
          style="margin-top: 14px; border-radius: 16px"
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: 0.1em 0.1em 0.2em grey; color: primary"
          >
            Atividade
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="codigo"
                :rules="[rules.required]"
                label="Código Fornecedor*"
                required
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
        </v-card>
        <crud-card title="Dados Principais">
          <v-container class="py-0">
            <v-row
              align="center"
              justify="center"
            >
              <v-radio-group
                v-model="picked"
                color="primary"
                row
                mandatory
              >
                <v-radio
                  value="juridica"
                  disabled
                  label="Pessoa Jurídica"
                >
                  Pessoa Jurídica
                </v-radio>
                <v-radio
                  value="fisica"
                  disabled
                  label="Pessoa Física"
                >
                  Pessoa Física
                </v-radio>
              </v-radio-group>
            </v-row>
          </v-container>
          <div v-if="picked === 'fisica'">
            <crud-pessoa-fisica-base
              :exclude="['sexo', 'data_nascimento']"
              :disabled="disabledFields.pfis"
              :input-outlined="true"
            >
              <template v-slot:append>
                <crud-contato
                  class="mt-0 ml-0 mr-0"
                  :telefones="telefones"
                  :cols="6"
                  :input-outlined="true"
                />
              </template>
            </crud-pessoa-fisica-base>
          </div>
          <div v-if="picked === 'juridica'">
            <crud-pessoa-juridica-base
              :optional="['nome_fantasia', 'inscricao_estadual']"
              :disabled="disabledFields.pjur"
            >
              <template v-slot:append>
                <crud-contato
                  class="ml-0"
                  :telefones="telefones"
                  :cols="6"
                  :input-outlined="true"
                />
              </template>
            </crud-pessoa-juridica-base>
          </div>
        </crud-card>
        <crud-localizacao
          title="Endereço"
          class="mt-3"
        />
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="text-none mr-3 text-white"
            color="error"
            rounded
            @click="
              $router.push({ path: `/${$user.get().role}/fornecedores` })
            "
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="cadastrarFornecedor"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import FornecedorFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/fornecedor'
  import { mapState, mapActions } from 'vuex'
  export default {
    data () {
      return {
        valid: false,
        loadedData: false,
        telefones: [
          { tipo: 'Comercial', format: 'com', title: 'Telefone', required: true },
        ],
        rules: rules,
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/fornecedor', ['loading', 'isEditing']),
      ...mapFields('form', ['data.codigo', 'data.pessoaFis', 'data.nome_fantasia']),
      ...mapFields('form/fornecedor', ['picked', 'dialog']),
      disabledFields () {
        if (this.loaded || this.isEditing) {
          return { pfis: ['email', 'cpf'], pjur: ['cnpj', 'email'] }
        }
        return { pfis: ['cpf'], pjur: ['cnpj'] }
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.fornecedor) { this.$store.registerModule(['form', 'fornecedor'], FornecedorFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/fornecedor', [BOOTSTRAP_PROFILE, SUBMIT]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.picked = tipo === 'cpf' ? 'fisica' : 'juridica'
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/fornecedores/cadastro/`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      cadastrarFornecedor () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
